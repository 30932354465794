import Api from "../Api"


export default{
    getDiagnosticos(tipo=0){
    let strActivo = "";
    switch(tipo){
        case 0:strActivo="all";break;
        case 1:strActivo="activas";break;
        case 2:strActivo="inactivas";break;
    }
    let url = `/diagnosticos/${strActivo}`
    
   return  Api() .get(url)
  }
}