import Api from "../Api"


export default{
    getMedicos(tipo=0,residente=0){
    //tipo es 2 inactivos, 1 para activos, 0 para todos
    let strActivo = "";
    let strResidente = ""
    switch(tipo){
        case 0:strActivo="all";break;
        case 1:strActivo="activos";break;
        case 2:strActivo="inactivos";break;
    }
    if(residente == 1){
        strResidente = "residentes"
    }
    let url = `/doctores/${strActivo}/${strResidente}`
    
   return  Api() .get(url)
  }
}